import {PDF} from "../Components/PDF";
import biodiversity from "../Components/pdfs/biodiversityPDF.pdf";
import {PortInfo} from "../Components/Home";
import {useState} from "react";

const Biodieversity = ({setLastId, setSelected, setSelectedPagesNumber, setLastY}) => {
    const [hidden, setHidden] = useState(true)
    const [isZoom, setIsZoom] = useState(false)
    const [show, setShow] = useState(false);

    const Button = () => {
        const str = hidden ? 'Show Project' : 'Hide Project'
        return <div className={'showBtn noselect'}
                    onClick={() => setHidden((prev) => !prev)}
        >{str}</div>
    }
    return <div className={'container'} id={'biodiversity'}>
        <div className={'firstGrid'}/>
        <div className={'secondGrid'}>
            <PortInfo title={'Biodieversity'} date={'Spring 2021'} description={'The goal of this project was to create an infographic ' +
            'highlighting a world issue today. This poster is about the rapid decrease in biodiversity and what problems are causing it.'} />
        </div>
        <div className={`thirdGrid ${hidden ? 'thirdAnimRev' : 'thirdAnim'}`}>
            {hidden ? <Button /> :
                <div>
                    <PDF id={'biodiversity'} setLastId={setLastId} setLastY={setLastY}  setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}  show={show} setShow={setShow}  isZoom={isZoom} setIsZoom={setIsZoom} setHidden={setHidden} hidden={hidden} src={biodiversity} button={<Button/>} />
                </div>
            }
        </div>
    </div>
};

export default Biodieversity;