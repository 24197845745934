import {PDF} from "../Components/PDF";
import resume from "../Components/pdfs/resume.pdf";
import {PortInfo} from "../Components/Home";
import {useState} from "react";

const Resume = ({setLastId, setSelected, setSelectedPagesNumber, setLastY}) => {
    const [hidden, setHidden] = useState(true);
    const [isZoom, setIsZoom] = useState(false);
    const [show, setShow] = useState(false);

    const Button = () => {
        const str = hidden ? 'Show Resume' : 'Hide Resume'
        return <div className={'showBtn noselect'}
                    onClick={() => setHidden((prev) => !prev)}
        >{str}</div>
    }

    return <div className={'container'} id={'resume'}>
        <div className={'firstGrid'}/>
        <div className={'secondGrid'}>
            <PortInfo title={'Resume'} date={'Last Updated: December 2021'} description={''} />
        </div>
        <div className={`thirdGrid ${hidden ? 'thirdAnimRev' : 'thirdAnim'}`}>
            {hidden ? <Button /> :
                <div>
                    <PDF id={'resume'} setLastId={setLastId} setLastY={setLastY} setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber} show={show} setShow={setShow} isZoom={isZoom} setIsZoom={setIsZoom} setHidden={setHidden} hidden={hidden} src={resume} button={<Button/>} />
                </div>
            }
        </div>
    </div>
}

export default Resume;