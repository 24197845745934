import {PDF} from "../Components/PDF";
import ro from "../Components/pdfs/Ropdf.pdf";
import {PortInfo} from "../Components/Home";
import {useState} from "react";

const Ro = ({setLastId, setSelected, setSelectedPagesNumber, setLastY}) => {
    const [hidden, setHidden] = useState(true)
    const [isZoom, setIsZoom] = useState(false)
    const [show, setShow] = useState(false);



    const Button = () => {
        const str = hidden ? 'Show Project' : 'Hide Project'
        return <div className={'showBtn noselect'}
                    onClick={() => setHidden((prev) => !prev)}
        >{str}</div>
    }

    return <div className={'container'} id={'ro'}>
        <div className={'firstGrid'}/>
        <div className={'secondGrid'}>
            <PortInfo title={'Ro Catalog'} date={'Fall 2021 - Current'} description={'Ro Catalog is an experimental ecommerce apparel website ' +
            'showcasing a better online shopping experience. This project starting as part of my design capstone, but I plan on continuing its ' +
            'development.'} />
            <p style={{backgroundColor: 'yellow', display: 'inline-block'}}><i>Under Construction !</i></p><br/><br/>
            <a href={'https://ro-studios.netlify.app/'}>Link to website!</a>
        </div>
        <div className={`thirdGrid ${hidden ? 'thirdAnimRev' : 'thirdAnim'}`}>
            {hidden ? <Button /> :
                <div>
                    <PDF id={'ro'} setLastId={setLastId} setLastY={setLastY}  setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}  show={show} setShow={setShow} isZoom={isZoom} setIsZoom={setIsZoom} setHidden={setHidden} hidden={hidden} src={ro} button={<Button/>} />
                </div>
            }
        </div>
    </div>
};

export default Ro;