import './App.css';
import {Home} from "./Components/Home";
import React, {useEffect, useState} from "react";
import {Document, Page} from "react-pdf";
import DocumentMeta from 'react-document-meta';

const App = () => {
    const [selected, setSelected] = useState(undefined)
    const [selectedPagesNumber, setSelectedPagesNumber] = useState(0)
    const [lastY, setLastY] = useState(0);
    const [lastId, setLastId] = useState('');
    const [zoom, setZoom] = useState(0)

    const meta = {
        title: 'Tommy Sarni',
        description: 'Welcome to my site. My name is Tommy Sarni. I am a software developer and designer.',
        canonical: 'http://tommysarni.com',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'tommy, sarni, portfolio, resume'
            }
        }
    };

    const Zoom = ({positive}) => {
        let ans = (prev) => Math.min(prev + 1, 3)
        if (!positive) ans = (prev) => Math.max(prev - 1, 0)
        return <div className={'zoomBtn noselect'} style={{color:'white'}}
                    onClick={() => setZoom((prev) => ans(prev))}
        >{positive ? 'Zoom In' : 'Zoom Out'}</div>
    }

    const Zooms = () => {
        return <div style={{position: 'fixed', left: 0, bottom: 0, zIndex: '5000', mixBlendMode: 'difference', fontWeight: 'bold'}}>
            <Zoom positive={false}/>
            <Zoom positive={true}/>
        </div>
    }

    useEffect( () =>  {
        document.body.classList.add('preload')
        setTimeout(function(){
            document.body.className="";
        },500);
    }, [])


    useEffect( () =>  {
        if (lastId) document.getElementById(lastId).scrollIntoView(true);
        setZoom(0);
    }, [lastId, selected])

  return (
      <DocumentMeta {...meta}>
    <div style={{overflow: selected ? 'hidden' : '', width: '100%'}}>
        {selected && <Zooms />}
        {selected &&  <div id={'topContainer'} className={'topContainer'} style={{display: selected ? '' : 'none'}} onClick={() => {
            setSelected(false);
        }}>
            <Document
                className={`preview zoom${zoom}`}
                file={selected}
                onLoadError={(er) => console.log(er)}
            >
                {[...Array(selectedPagesNumber).keys()].map((num) => num + 1).map((n) => {
                    return <Page key={n + 1} style={{width:'300px',objectFit: 'contain', position: 'absolute'}} pageNumber={n} />
                })}
            </Document>
        </div>}

      <Home setLastId={setLastId} selected={selected} setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber} setLastY={setLastY}/>
    </div>
      </DocumentMeta>
  );
}

export default App;
