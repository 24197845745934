import React, {useEffect, useState} from "react";
import '../App.css'
import Resume from "../Projects/Resume";
import Ro from "../Projects/Ro";
import Coffee from "../Projects/Coffee";
import Lifeswork from "../Projects/Lifeswork";
import TypeBook from "../Projects/TypeBook";
import Biodieversity from "../Projects/Biodieversity";
import TypePosters from "../Projects/TypePosters";
import TeaTime from "../Projects/TeaTime";

export const Home = ({setLastId, selected, setSelected, setSelectedPagesNumber, setLastY}) => {

    return <div style={{overflowY: (selected) ? 'hidden' : 'auto', position: (selected) ? 'absolute' : 'relative', height: '100%'}}>
    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', filter: (selected) ? 'blur(8px)' : ''}}>

            <div className={'container'} style={{alignItems: 'center', marginBottom: '50px'}}>
                <div className={'firstGrid'} />
                <div className={'secondPlain'}>
                    <h1 id={'titleName'} style={{marginBottom: '5px'}}>Tommy Sarni</h1>
                    <p id={'email'} style={{marginTop: '5px'}} onClick={() =>
                    {window.location.href = "mailto:tommy.sarni11@gmail.com?subject=Hello%20Tommy!"}}><i>tommy.sarni11@gmail.com</i></p>
                </div>
                <div className={'thirdPlain'}>
                </div>
            </div>
            <div id={'empty'} className={'container'} style={{alignItems: 'center', marginBottom: '50px'}}>

            </div>
            <Resume setLastId={setLastId} setLastY={setLastY} setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}/>
            <Ro setLastId={setLastId}  setLastY={setLastY} setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}/>
            <Coffee setLastId={setLastId}  setLastY={setLastY}  setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}/>
            <Lifeswork setLastId={setLastId}  setLastY={setLastY}  setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}/>
            <TypeBook setLastId={setLastId}  setLastY={setLastY} setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}/>
            <Biodieversity setLastId={setLastId}  setLastY={setLastY} setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}/>
            <TypePosters setLastId={setLastId}  setLastY={setLastY} setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}/>
            <TeaTime setLastId={setLastId}  setLastY={setLastY} setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}/>
        </div>


    </div>
}


export const PortInfo = ({title, date, description}) => {
    return <div>
        <h1 id={'sectionTitle'} style={{marginTop: '0'}}>{title}</h1>
        <p><i>{date}</i></p>
        <h5 className={'portText'} style={{width: '90%'}}>{description}</h5>
    </div>
}













