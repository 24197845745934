import {PDF} from "../Components/PDF";
import book from "../Components/pdfs/Final Book.pdf";
import {PortInfo} from "../Components/Home";
import {useState} from "react";

const TypeBook = ({setLastId, setSelected, setSelectedPagesNumber, setLastY}) => {
    const [hidden, setHidden] = useState(true)
    const [isZoom, setIsZoom] = useState(false)
    const [show, setShow] = useState(false);



    const Button = () => {
        const str = hidden ? 'Show Project' : 'Hide Project'
        return <div className={'showBtn noselect'}
                    onClick={() => setHidden((prev) => !prev)}
        >{str}</div>
    }

    return <div className={'container'} id={'typebook'}>
        <div className={'firstGrid'}/>
        <div className={'secondGrid'}>
            <PortInfo title={'Type Book'} date={'Spring 2021'} description={'This project was to typeset the book, Designing with Type. ' +
            'This project included designing book covers, creating grid layouts for type to follow, editing individual paragraphs, and selecting images ' +
            'to include with the text. The book is set in Baskerville and Futura.'} />
        </div>
        <div className={`thirdGrid ${hidden ? 'thirdAnimRev' : 'thirdAnim'}`}>
            {hidden ? <Button /> :
                <div>
                    <PDF id={'typebook'} setLastId={setLastId} setLastY={setLastY}  setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}  show={show} setShow={setShow}  isZoom={isZoom} setIsZoom={setIsZoom} setHidden={setHidden} hidden={hidden} src={book} button={<Button/>} />
                </div>
            }
        </div>
    </div>
};

export default TypeBook;