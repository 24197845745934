import {PDF} from "../Components/PDF";
import coffee from "../Components/pdfs/CoffeeFinal.pdf";
import {PortInfo} from "../Components/Home";
import {useState} from "react";

const Coffee = ({setLastId, setSelected, setSelectedPagesNumber, setLastY}) => {

    const [hidden, setHidden] = useState(true);
    const [isZoom, setIsZoom] = useState(false);
    const [show, setShow] = useState(false);



    const Button = () => {
        const str = hidden ? 'Show Project' : 'Hide Project'
        return <div className={'showBtn noselect'}
                    onClick={() => setHidden((prev) => !prev)}
        >{str}</div>
    }

    return <div className={'container'} id={'coffee'}>
        <div className={'firstGrid'}/>
        <div className={'secondGrid'}>
            <PortInfo title={'Basics of Coffee Making'} date={'Fall 2021'} description={'This design was created for my Information Design class.' +
            ' The prompt for this assignment was to describe how something works by creating instructional and explanatory diagrams. I chose to look at ' +
            'caffe drinks and coffee makers, specifically a standard drip coffee maker and a cafe style espresso machine. '} />
        </div>
        <div className={`thirdGrid ${hidden ? 'thirdAnimRev' : 'thirdAnim'}`}>
            {hidden ? <Button /> :
                <div>
                    <PDF id={'coffee'} setLastId={setLastId} setLastY={setLastY}  setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}  show={show} setShow={setShow}  isZoom={isZoom} setIsZoom={setIsZoom} setHidden={setHidden} hidden={hidden} src={coffee} button={<Button/>} />
                </div>
            }
        </div>
    </div>
};

export default Coffee;