import {PDF} from "../Components/PDF";
import lifeswork from "../Components/pdfs/GD2.pdf";
import {PortInfo} from "../Components/Home";
import {useState} from "react";

const Lifeswork = ({setLastId, setSelected, setSelectedPagesNumber, setLastY}) => {

    const [hidden, setHidden] = useState(true)
    const [isZoom, setIsZoom] = useState(false)
    const [show, setShow] = useState(false);

    const Button = () => {
        const str = hidden ? 'Show Project' : 'Hide Project'
        return <div className={'showBtn noselect'}
                    onClick={() => setHidden((prev) => !prev)}
        >{str}</div>
    }

    return <div className={'container'} id={'lifeswork'}>
        <div className={'firstGrid'}/>
        <div className={'secondGrid'}>
            <PortInfo title={'Lifeswork'} date={'Spring 2021'} description={'This project was based on brand design for a Graphic Design class. ' +
            'I chose to develop my branding project around job searching and the importance of career exploration. ' +
            'Here you will see the process of my brand design including Market Research, Inspiration, Brand Elements, and three graphic design Series.'} />
        </div>
        <div className={`thirdGrid ${hidden ? 'thirdAnimRev' : 'thirdAnim'}`}>
            {hidden ? <Button /> :
                <div>
                    <PDF id={'lifeswork'} setLastId={setLastId} setLastY={setLastY}  setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}  show={show} setShow={setShow}  isZoom={isZoom} setIsZoom={setIsZoom} setHidden={setHidden} hidden={hidden} src={lifeswork} button={<Button/>} />
                </div>
            }
        </div>
    </div>
}

export default Lifeswork;