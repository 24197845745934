import {PDF} from "../Components/PDF";
import teatime from "../Components/pdfs/TeaTime.pdf";
import {PortInfo} from "../Components/Home";
import {useState} from "react";

const TeaTime = ({setLastId, setSelected, setSelectedPagesNumber, setLastY}) => {
    const [hidden, setHidden] = useState(true)
    const [isZoom, setIsZoom] = useState(false)
    const [show, setShow] = useState(false);



    const Button = () => {
        const str = hidden ? 'Show Project' : 'Hide Project'
        return <div className={'showBtn noselect'}
                    onClick={() => setHidden((prev) => !prev)}
        >{str}</div>
    }


    return <div className={'container'} id={'teatime'}>
        <div className={'firstGrid'}/>
        <div className={'secondGrid'}>
            <PortInfo title={'Tea Time'} date={'Summer 2019'} description={'This is my very first graphic design project! I was tasked with creating ' +
            'a company and the branding for it. Here is my mock company, Tea Time, that is a tea company targeting millennials.'} />
        </div>
        <div className={`thirdGrid ${hidden ? 'thirdAnimRev' : 'thirdAnim'}`}>
            {hidden ? <Button /> :
                <div>
                    <PDF id={'teatime'} setLastId={setLastId} setLastY={setLastY}  setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}  show={show} setShow={setShow} isZoom={isZoom} setIsZoom={setIsZoom} setHidden={setHidden} hidden={hidden} src={teatime} button={<Button/>} />
                </div>
            }
        </div>
    </div>
};

export default TeaTime;