import React, {useEffect, useState} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

export const PDF = ({id, setLastId, setLastY ,setSelected, setSelectedPagesNumber, setShow, button, src}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(true);

    Number.prototype.mod = function (n) {
        return ((this % n) + n) % n;
    };

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    },[])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setLoading(false);
        console.log(loading)
    }

    const updatePage = (isPositive) => {
        let res = (pageNumber + 1).mod(numPages + 1)
        if (res === 0) res = 1
        if (!isPositive) {
            res = (pageNumber - 1).mod(numPages + 1)
            if (res === 0) res = numPages
        }
        setPageNumber(res)
    }

    return (
            <div className={`pdfContainer zoom0`}>
                <div className={'btnHolder'}>
                    {button}
                </div>
                <div className={'pdf'}
                     style={{border: loading ? 'none' : ''}}
                     onClick={(ev) => {
                         setShow(true);
                         setSelected(src);
                         setSelectedPagesNumber(numPages);
                         setLastY(ev.screenY);
                         setLastId(id);
                     }}>
                    <Document
                        className={'doc'}
                        file={src}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={(er) => console.log(er)}
                        loading={<Loading/>}
                    >
                        <Page key={pageNumber} style={{width: '300px', objectFit: 'contain', position: 'absolute'}}
                              pageNumber={pageNumber}/>

                    </Document>
                </div>
                {numPages > 1  && <div id={'navBtn'}>
                    <div className={'showBtn nav'} onClick={() => updatePage(false)}>
                        Previous
                    </div>
                    <div className={'showBtn nav'} onClick={() => updatePage(true)}>
                        Next
                    </div>
                </div>}
                {!loading &&
                <p style={{fontStyle: 'italic'}}>Page {pageNumber} of {numPages}</p>
                }
            </div>
    );
}


const Loading = () => {
    return <div style={{display: 'flex', flexDirection: 'column', margin: '20px', justifyContent:'center'}}>
        <p><b>Loading</b></p>
        <div className={'load'}></div>
    </div>
}