import {PDF} from "../Components/PDF";
import posters from "../Components/pdfs/TypePosters.pdf";
import {PortInfo} from "../Components/Home";
import {useState} from "react";

const TypePosters = ({setLastId, setSelected, setSelectedPagesNumber, setLastY}) => {
    const [hidden, setHidden] = useState(true)
    const [isZoom, setIsZoom] = useState(false)
    const [show, setShow] = useState(false);

    const Button = () => {
        const str = hidden ? 'Show Project' : 'Hide Project'
        return <div className={'showBtn noselect'}
                    onClick={() => setHidden((prev) => !prev)}
        >{str}</div>
    }

    return <div className={'container'} id={'typeposters'}>
        <div className={'firstGrid'}/>
        <div className={'secondGrid'}>
            <PortInfo title={'Type Posters'} date={'Fall 2019'} description={'I created these posters as part of my Typography class. ' +
            'This project features designs in Didot, Verdana, and Sarni, a custom typeface I created.'} />
        </div>
        <div className={`thirdGrid ${hidden ? 'thirdAnimRev' : 'thirdAnim'}`}>

            {hidden ? <Button /> :
                <div>
                    <PDF id={'typeposters'} setLastId={setLastId} setLastY={setLastY}  setSelected={setSelected} setSelectedPagesNumber={setSelectedPagesNumber}  show={show} setShow={setShow} isZoom={isZoom} setIsZoom={setIsZoom} setHidden={setHidden} hidden={hidden} src={posters} button={<Button/>} />
                </div>
            }
        </div>
    </div>
};

export default TypePosters;